import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

import './sass-globals/form.scss'

export const ContactForm: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data: { name: string, email: string, message: string }) => {
    const { name, email, message } = data,
      successdiv = document.querySelector('.contact-form__success') as HTMLElement,
      errordiv = document.querySelector('.contact-form__error') as HTMLElement;

    try {
      const templateParams = {
        name,
        email,
        message
      };
      await emailjs.send(
        'service_v6abgh8',
        'template_kr8k5rj',
        templateParams,
        'user_oo0GS0wd5WrEAnzYEJx0Q'
      ).then((response) => {
        if(response.status == 200) {
          successdiv.classList.toggle('contact-form__success--show')
        } else {
          errordiv.classList.toggle('contact-form__error--show')
        }
      });
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form className="contact-form" id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className='contact-form__content'>
        <div className='contact-form__section contact-form__section--name'>
          <input
            type='text'
            {...register('name', {
              required: { value: true, message: 'Please enter your name' },
              maxLength: {
                value: 30,
                message: 'Please use 30 characters or less'
              }
            })}
            name='name'
            className='form-control formInput'
            placeholder='Name'
          ></input>
          {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
        </div>
        <div className='contact-form__section contact-form__section--email'>
          <input
            type='email'
            {...register('email', {
              required: true,
              pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            })}
            name='email'
            className='form-control formInput'
            placeholder='Email address'
          ></input>
          {errors.email && (
            <span className='errorMessage'>Please enter a valid email address</span>
          )}
        </div>
        <div className='contact-form__section contact-form__section--message'>
          <textarea
            rows={3}
            {...register('message', {
              required: true
            })}
            name='message'
            className='form-control formInput'
            placeholder='Message'
          ></textarea>
          {errors.message && <span className='errorMessage'>Please enter a message</span>}
        </div>
      </div>
      <button className='submit-btn' type='submit'>
        Send Message
      </button>
      <div className='contact-form__success'>Email Sent! Thank you - IC</div>
      <div className='contact-form__error'>
        Sorry could not send email. I'll see what the issue is. 
        In the meantime contact me at isaiah.cornejo@gmail.com - IC
      </div>
    </form>

  );
};

export default ContactForm;