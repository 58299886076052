import { FunctionComponent } from 'react'
import './footer.scss'
import classNames from 'classnames'

export interface FooterProps {
  list?: string[]
  listname?: string
}

export const Footer: FunctionComponent<FooterProps> = ({
  children
}) => {
  const infoClass = classNames('zeke-footer')
  return (
    <footer
      className={infoClass}
    >
      {children}
    </footer>
  )
}
