import React, { FunctionComponent } from 'react'
import { Nav } from '../../components/Nav'
import { Mode } from '../../components/Mode'
import './headermenu.scss'
import Zeke from '../../images/zeke.png'

// import classNames from 'classnames'

// import { Link } from 'react-router-dom'

// export const stickyNavThreshold = 98

// export interface HeaderMenuProps {
//   // navchange?: boolean
// }

export const HeaderMenu: FunctionComponent = () => { //<HeaderMenuProps> 

  return (
    <header className='header' data-module='header'> 
      <div className="header__content">
        <div className="header__logo">
          <div className="header__logo-icon">
            <img src={Zeke} alt=""/>
          </div>
        </div>
        <div className="header__nav">
          <Nav />
        </div>
        <div className="header__mode">
          <Mode />
        </div>
      </div>
    </header>
  )
}
