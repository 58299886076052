import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface PageSectionProps {
  nopadding?: boolean
  twocol?: boolean
  backgroundImg?: string
  id?: string
}

export const PageSection: FunctionComponent<PageSectionProps> = (props) => {
  return (
    <div
      className={classNames('pagesection', {
        'pagesection--2col': props.twocol,
        'pagesection--no-padding': props.nopadding,
      })} 
      id={props.id}
      style={
        props.backgroundImg
          ? { backgroundImage: `url(${props.backgroundImg})` }
          : {}
      }
    >
      <div className="pagesection__container">{props.children}</div>
    </div>
  )
}
