import classNames from 'classnames'
import React, { FunctionComponent, ReactNode } from 'react'
import './content.scss'

export interface ContentProps {
  children: ReactNode
  className?: string
}

/**
 * Baseline styling for main content of a page
 */
export const Content: FunctionComponent<ContentProps> = ({
  children,
  className,
}) => {
  const classes = classNames('content', className)
  return <main className={classes}>{children}</main>
}
