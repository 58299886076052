import { FunctionComponent } from "react";
import "./zeke-wrapper.scss";
import { HeaderMenu, Footer } from "./sections";
import classNames from 'classnames'
import { HomePage } from './pages'
// import { getPageName } from './utils'

export interface MainProps {
  loadAthena?: boolean;
}

// note: this mark up and set up is temporary and needs to be refactored.
const Main: FunctionComponent<MainProps> = () => {
  // const [mode, setMode] = useState('light');
  const mainClass = classNames('zeke-wrapper')
  // const location = useLocation()
  // const page = getPageName(location.pathname)
  // const mainClass = classNames('Main_Container', `Page_${page}`)
  return (
    <div className={mainClass}>
      <HeaderMenu />
      <HomePage />
      {/* <Switch>
        <Route path="/products">
          <HeaderFeature />
          <MenuHeader />
          <ProductsPage />
          <Footer />
          <TradeMarkFooter />
        </Route>
        <Route path="/solutions">
          <HeaderFeature />
          <MenuHeader />
          <SolutionsPage />
          <Footer />
          <TradeMarkFooter />
        </Route>
        <Route path="/trial">
          <HeaderFeature />
          <MenuHeader />
          <TrialPage />
          <Footer />
          <TradeMarkFooter />
        </Route>
        {props.loadAthena && (
          <Route path="/athena">
            <AthenaApplication />
          </Route>
        )}
        <Route exact path="/">
          <HeaderFeature />
          <MenuHeader />
          <HomePage />
          <Footer />
          <TradeMarkFooter />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch> */}
      <Footer>
        <span>This site was built using: <br />
        Design: Adobe XD, Adobe Photoshop, Figma <br />
        Front End: React, JS/Typescript, CSS/Sass
        </span>
        <p>&copy; Copyright Isaiah Cornejo 2021</p>
      </Footer>
    </div>
  );
};

export default Main;
