import { FunctionComponent, ReactElement, useState, useEffect } from 'react'
import './mode.scss'

import { ReactComponent as LightMode } from '../../images/light_mode.svg'
import { ReactComponent as DarkMode } from '../../images/dark_mode.svg'

export interface ModeProps {
  alt?: string | ReactElement
}

export const Mode: FunctionComponent<ModeProps> = () => {

	const [mode, setMode] = useState(() => localStorage.getItem('mode'));

	useEffect(() => {
		const wrapper = document.querySelector('.zeke-wrapper') as HTMLElement;
		const checkbox = document.querySelector('.mode__input') as HTMLFormElement;

		if(mode === 'dark') {
			wrapper.classList.add('zeke-wrapper--dark');
			localStorage.setItem('mode', 'dark');
			checkbox.checked = true;

		} else {
			wrapper.classList.remove('zeke-wrapper--dark');
			localStorage.setItem('mode', 'light');
		}
	}, [mode])


	return (
		<div className='mode'>
				<span className='mode__light'><LightMode /></span>
				<input type='checkbox' id='toggle' className='mode__input' 
					onClick={() => setMode(mode => (mode === 'dark' ? 'light' : 'dark'))} 
				/>
				<label htmlFor='toggle' className='mode__switch'></label>
				<span className='mode__dark'><DarkMode /></span>
		</div>
	);
};
