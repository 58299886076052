import { FunctionComponent, ReactElement } from 'react'
import { Link } from 'react-scroll'
import './nav.scss'

export interface NavProps {
  nav?: string | ReactElement
  anchortag?: string
}

export const Nav: FunctionComponent<NavProps> = () => {
  return (
    <ul className='nav' data-module="anchor">
      <li>
        <Link 
          to='section1'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Work
        </Link>
      </li>
      <li>
        <Link 
          to='section2'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          About
        </Link>
      </li>
      <li>
        <Link 
          to='section3'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Contact
        </Link>
      </li>
    </ul>
  )
}
