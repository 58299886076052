// import classNames from 'classnames'
import { FunctionComponent } from 'react'
import parse from 'html-react-parser'
// import { Link } from 'react-router-dom'
import { HeroBlock, Content, FolioCards, InfoBlock, ProductBlock } from '../../components'
import { PageSection } from '../../sections/PageSection/PageSection'
import Form from '../../login'
import homePageFPO from '../../images/den-hero.jpg'

import Logo1 from '../../images/logos/nike.svg'
import Logo2 from '../../images/logos/ebay.svg'
import Logo3 from '../../images/logos/discover.svg'
import Logo4 from '../../images/logos/tufts.svg'
import Logo5 from '../../images/logos/oakley.svg'
import Logo6 from '../../images/logos/staples.svg'



import './homepage.scss'

export const HomePage: FunctionComponent = () => {
  const prodList = [
    Logo1,
    Logo2,
    Logo3,
    Logo4,
    Logo5,
    Logo6,
  ]

  return (
    <Content className={`homepage-container`}>

      <HeroBlock
        heading={
          <>
            Working to get things done and bleeding HTML5
          </>
        }
        subheading="Isaiah Cornejo- UI/UX Engineer"
        background={homePageFPO}
        social
      >
        <a href="https://www.linkedin.com/in/isaiah-cornejo/" rel="noreferrer" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
          </svg>
        </a>
      </HeroBlock>
      <FolioCards />
      <PageSection twocol id="section2">
        <InfoBlock
          infosubtitle="About"
          heading="My Info"
          subheading="My name is Isaiah. I like to write code. I reside in the Boston area in Massachusetts. My main experience is in Front End but have a big passion for UX Designs. Take this site...I started using more Figma to help me Design this webpage."
        >
          <div className="info-block__infotag">Daily Work</div>
          <p>HTML5, CSS3/SASS, JavaScript/ES6, Typescript, React, e-Commerce,<br /> AB Testing, Webpack, CMS </p>

          <div className="info-block__infotag">Tools</div>
          <p>Adobe XD, Photoshop, Premiere, After Effects, Google Optimize, Zeplin, Jira, GIT, Terminal, VS Code, Sitecore</p>
        </InfoBlock>
        <InfoBlock subheading="Other Products/Companies I have worked with:" >
          <ProductBlock list={prodList} />
        </InfoBlock>
      </PageSection>

      <PageSection id="section3">
        <InfoBlock
          infosubtitle="Contact"
          heading="Get In Touch"
          subheading={parse(
            "If you’d like to find out more about my work and my process, <br />please get in touch with me."
          )}
        >
          <Form />
        </InfoBlock>
      </PageSection>
    </Content>
  )
}