import React, { FunctionComponent } from 'react'

export interface FolioCardProps {
  image?: string
  description?: string
  headline?: string
  linktext?: string
  features?: boolean
  mobile?: boolean
  linkitem?: string
}
export const FolioCard: FunctionComponent<FolioCardProps> = (props) => (
  <div
    className={`folio-card__item ${
      props.features ? 'folio-card__item--feature' : ''
    }`}
  >
    {props.children ? (
      props.children
    ) : (
      <>
        {props.image && (
          <>
          <a href={props.linkitem} rel="noreferrer" target="_blank">
            <div className={`folio-card__image ${props.mobile? 'folio-card__image--mobile':''}`}>
              <img src={props.image} className="folio-cards__image-icon" alt="" />
            </div>
          </a>
          </>
        )}
        <h2 className="folio-card__title">{props.headline}</h2>
        {props.linktext && (
          <a href="#" className="folio-card__link">
            {props.linktext}
          </a>
        )}
      </>
    )}
  </div>
)