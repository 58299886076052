import React, { FunctionComponent, ReactElement } from 'react'
import './hero.scss'
import classNames from 'classnames'
import { Title } from '..'

export interface HeroBlockProps {
  heading: ReactElement | string
  subheading?: ReactElement | string
  background: string
  social?: boolean
  socialname?: string
}

export const HeroBlock: FunctionComponent<HeroBlockProps> = ({
  heading,
  subheading = null,
  background,
  children,
}) => {
  const heroClass = classNames('hero')
  return (
    <div
      className={heroClass}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={`hero__content`}>
        <div className={`hero__copy`}>
          <Title>{heading}</Title>
          {subheading && (
            <p className={`hero__subheading`}>{subheading}</p>
          )}
        </div>
      </div>
      <div className={`hero__socialinfo`}>
        {children}
      </div>
    </div>
  )
}
