import React from 'react'
import { LoadScripts } from './components'
import Main from './Main'
// import logo from './images/logo.svg';
// import './App.css';

const App: React.FunctionComponent = () => {
  return (
    <>
      <LoadScripts />
      <Main />
    </>
  )
}

export default App
