import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { FolioCard } from './FolioCard'
import './foliocard.scss'

import Producta from '../../images/lmi-laptop.png'
import Productb from '../../images/lp-laptop.png'
import Productc from '../../images/gtpcmobile.png'
import Productd from '../../images/baystatemobile.png'

export interface FolioCardsProps {
  className?: string
}

export const FolioCards: FunctionComponent<FolioCardsProps> = ({
  className,
}) => {
  const classes = classNames('content', className)
  return  <section className={classes}>
    <div className="content__block">
      <div className="folio-card folio-card--2column" id="section1">
        <FolioCard 
          image={Producta}
          headline="LogMeIn"
          linkitem="https://www.logmein.com/"
        />
        <FolioCard 
          image={Productb}
          headline="LastPass"
          linkitem="https://www.lastpass.com/"
        />
        <FolioCard 
          image={Productc}
          mobile={true}
          headline="GoToMyPC"
          linkitem="https://www.gotomypc.com/"
        />
          <FolioCard 
          image={Productd}
          mobile={true}
          headline="Baystate Health"
          linkitem="https://www.baystatehealth.org/"
        />          
      </div>
    </div>
  </section>
}
