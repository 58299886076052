import { FunctionComponent } from 'react'
import './productblock.scss'
import classNames from 'classnames'

export interface ProductBlockProps {
  list?: string[]
  listname?: string
}

export const ProductBlock: FunctionComponent<ProductBlockProps> = ({
  list,
  listname
}) => {
  const infoClass = classNames('product-block')
  return (
    <div
      className={infoClass}
    >
      {list && (
        <ul>
          {list.map((list) => (
            <li key={list}>
              <img src={list} alt={listname} />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
