import { FunctionComponent, ReactElement } from 'react'
import './infoblock.scss'
import classNames from 'classnames'
import { Title } from '..'

export interface InfoBlockProps {
  infosubtitle?: string
  heading?: ReactElement | string
  subheading?: string | ReactElement | ReactElement[]
  infotag?: string
}

export const InfoBlock: FunctionComponent<InfoBlockProps> = ({
  infosubtitle,
  heading,
  subheading = null,
  infotag,
  children,
}) => {
  const infoClass = classNames('info-block')
  return (
    <div
      className={infoClass}
    >
      <div className={`info-block__content`}>
        <div className={`info-block__copy`}>
          {infosubtitle && (
            <div className="info-block__subtitle">{infosubtitle }</div>
          )}
          <Title>{heading}</Title>
          {subheading && (
            <div className={`info-block__subheading`}>{subheading}</div>
          )}
          
          {infotag && (
            <span className={`info-block__infotag`}>{infotag}</span>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
